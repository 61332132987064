/* eslint-disable indent */

import * as Sentry from "@sentry/node";

import {
	APIError,
	APIResponse,
	Checkout,
	CheckoutAPIResponse,
	DeepPartial,
	FetchCheckoutAPIResponse,
	UpdateCheckout,
	User,
	UserExperiments,
} from "@/types/index";

import { initializeApollo } from "./apolloClient";
import {
	ADD_DISCOUNT_CODE_MUTATION,
	CHECKOUT_UPSERT_MUTATION,
	GET_CHECKOUT_QUERY,
	VALIDATE_EMAIL_MUTATION,
	VALIDATE_PHONE_MUTATION,
	VALIDATE_POSTCODE_MUTATION,
} from "./queries";

import { CreateCheckoutFromAPIData } from "../helpers";

// FORMAT THE BASKET FOR THE CHECKOUT
const createAPICheckoutObjectFactory = (
	checkout: Checkout & UpdateCheckout,
	user: Partial<User> | null,
	experiments: UserExperiments,
	isLoggedIn: boolean
): DeepPartial<CheckoutAPIResponse> => {
	const voucherCode = user?.voucher
? user.voucher.code
: "";

	const referralToken =
		user?.referral && !isLoggedIn
? user.referral.token
: null;

	const products = checkout.lineItems?.map((item) => {
		if (!item.pet) {
			const singleItems = item.items.map((thisItem) => {
				return {
					variant_id: thisItem.variant_id,
					quantity: thisItem.quantity,
					needs_scoops: !!thisItem.needs_scoops,
				};
			});

			return {
				items: singleItems,
			};
		}

		const petItems = item.items.map((item) => {
			return {
				variant_id: item.variant_id,
				subscription_variant_id: item.subscription_variant_id,
				quantity: item.quantity,
				price: Math.round(item.original_price || item.price),
				tags: item.tags,
				benefits: item.benefits,
			};
		});

		const healthIssuesConcat = item.pet.improveHealth
			? [
					...(item.pet.healthIssues || []),
					...(item.pet.digestion?.includes("anal gland issues")
						? ["anal gland issues"]
						: []),
					...(item.pet.coat &&
					item.pet.coat.filter(
						(issue) => issue !== "tear stains" && issue !== "none"
					)?.length > 1
						? ["sensitive skin"]
						: []),
					...(item.pet.coat?.includes("tear stains")
? ["tear stains"]
: []),
					...(item.pet.digestion?.includes("a sensitive stomach")
						? ["a sensitive stomach"]
						: []),
			  ]
			: item.pet.healthIssues;

		const healthIssuesFiltered =
			healthIssuesConcat && healthIssuesConcat.length > 1
				? healthIssuesConcat.filter((issue) => issue !== "none")
				: healthIssuesConcat;

		const healthIssuesDuplicatesRemoved = [...new Set(healthIssuesFiltered)];

		return {
			pet: {
				activity_level: item.pet.activity,
				size: item.pet.shape,
				age: item.pet.age,
				allergies: item.pet.allergies,
				breed: item.pet.breed,
				fussy: item.pet.fussy,
				goal: item.pet.goal,
				gender: item.pet.gender,
				treats_fed: item.pet.treatsFed,
				diet: item.pet.diet,
				birthday: item.pet.birthday,
				neutered: item.pet.neutered,
				issues: healthIssuesDuplicatesRemoved,
				name: item.pet.name,
				weight: item.pet.weight,
				life_stage: item.pet.lifestage,
				species: "dog",
				petId: item.pet.petId,
				working_dog: item.pet.workingDog,

				// AB test
				digestion: item.pet.digestion,
				behavioural: item.pet.behavioural,
				stools: item.pet.stools,
				improve_health: item.pet.improveHealth,
				mobility: item.pet.mobility,
				oral: item.pet.oral,
				coat: item.pet.coat,

				subscription: {
					scoops_food:
						item.pet.planScoops ||
						item.pet.scoopsFood ||
						item.pet.subscription?.scoops_food,
					scoops_water:
						item.pet.planScoops ||
						item.pet.scoopsWater ||
						item.pet.subscription?.scoops_water,
					length:
						item.pet.planLength ||
						item.pet.subLength ||
						item.pet.subscription?.length,
					trial_length:
						item.pet.trialLength || item.pet.subscription?.trial_length,
					price_test: item.pet.price_test,
					selected_plan:
						item.pet.selectedPlan || item.pet.subscription?.selected_plan,
					base_size: item.pet.baseSize || item.pet.subscription?.base_size,
				},
			},
			items: petItems,
		};
	});

	let customer = checkout.customer
		? {
				customer: {
					initial_channel:
						checkout.customer.initial_channel ||
						user?.initialChannel ||
						undefined,
					first_name: checkout.customer?.first_name,
					last_name: checkout.customer?.last_name,
					phone: checkout.customer?.phone,
					email: checkout.customer?.email,
					customer_id: user?.checkoutDetails?.customer_id
						? `${user?.checkoutDetails?.customer_id}`
						: checkout?.customer?.customer_id
						? `${checkout?.customer?.customer_id}`
						: undefined,
					password: checkout.customer?.password || "",
					contact_via_email:
						typeof checkout.customer?.contact_via_email !== "undefined"
							? checkout.customer?.contact_via_email
							: true,
				},
		  }
		: {};

	let address = checkout.address
		? {
				address: {
					line_1: checkout.address?.line_1 || "",
					line_2: checkout.address?.line_2 || "",
					city: checkout.address?.city || "",
					province: checkout.address?.province || "",
					post_code: checkout.address?.post_code || "",
					parcelshop_id: checkout.address?.parcelshop_id || "",
					country: "United Kingdom",
				},
		  }
		: {};

	if (user?.accessToken && user?.checkoutDetails) {
		customer = {
			customer: {
				initial_channel:
					checkout.customer?.initial_channel ||
					user.initialChannel ||
					undefined,
				first_name:
					checkout.customer?.first_name || user.checkoutDetails.customer_first,
				last_name:
					checkout.customer?.last_name || user.checkoutDetails.customer_last,
				phone:
					checkout.customer?.phone || `${user.checkoutDetails.customer_phone}`,
				email: checkout.customer?.email || user.checkoutDetails.customer_email,
				customer_id: `${user.checkoutDetails.customer_id}`,
				password: "",
				contact_via_email:
					typeof checkout.customer?.contact_via_email !== "undefined"
						? checkout.customer?.contact_via_email
						: true,
			},
		};
		address = {
			address: {
				line_1:
					checkout.address?.line_1 || user.checkoutDetails.address_line1 || "",
				line_2:
					checkout.address?.line_2 || user.checkoutDetails.address_line2 || "",
				city: checkout.address?.city || user.checkoutDetails.address_city || "",
				province:
					checkout.address?.province ||
					user.checkoutDetails.address_province ||
					"",
				post_code:
					checkout.address?.post_code ||
					user.checkoutDetails.address_postcode ||
					"",
				parcelshop_id:
					checkout.address?.parcelshop_id ||
					user.checkoutDetails.address_parcelshop_id ||
					"",
				country: "United Kingdom",
			},
		};
	}

	const baseData = {
		send_abandoned_email_now: checkout.send_abandoned_email_now,
		delivery_date: user?.delivery_date || checkout.delivery_date,
		discount_code:
			checkout.force_discount_code ||
			voucherCode ||
			checkout.discount_code ||
			"",
		process_next_day: !!(user?.sales_person_id || checkout.sales_person_id),
		sales_person_id:
			user?.sales_person_id || checkout.sales_person_id || undefined,
		source: user?.source,
		referral_token: referralToken || undefined,
		tests: experiments
? JSON.stringify(experiments)
: "",
		products,
		properties: {
			hear_about_us_reason:
				checkout.properties?.hear_about_us_reason || undefined,
			buy_motivation: checkout.properties?.buy_motivation || undefined,
			nearly_stopped: checkout.properties?.nearly_stopped || undefined,
		},
		use_terminal: false,
		...customer,
		...address,
	};

	return baseData;
};

export const getCheckout = async <T extends boolean>(
	id: string,
	params: Record<string, unknown> = {},
	transformData: T = true as T
): Promise<{
	error: null | { message: string }[];
	data: T extends true ? Checkout : CheckoutAPIResponse;
}> => {
	const query = GET_CHECKOUT_QUERY;
	const variables = {
		id: id,
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.query({
			query: query,
			variables,
			errorPolicy: "all",
			fetchPolicy: "network-only",
			context: {
				clientName: "checkoutAPI",
				headers: {
					"Content-Type": "application/json",
				},
			},
			...params,
		})
		.then((checkout: APIResponse<FetchCheckoutAPIResponse>) => {
			if (checkout.errors) {
				console.log(JSON.stringify(checkout));
				
				return {
					error: checkout.errors,
					data: null
				};
			} else {
				// what was this for?
				// if (checkout.data.getCheckout.completed) {
				// return { error: false, data: null };
				// }

				return {
					error: null,
					data: transformData
						? CreateCheckoutFromAPIData(checkout.data.getCheckout)
						: checkout.data.getCheckout,
				};
			}
		})
		.catch((error: APIError) => {
			console.log(JSON.stringify(error));
			console.log(error, variables);
			Sentry.captureException(new Error(error.message));
		});
};

// UPDATE CHECKOUT OR CREATE IF NON
export async function putCheckout (
	checkout: Checkout & UpdateCheckout,
	user: Partial<User> | null,
	experiments: UserExperiments,
	checkoutID: string = "",
	validate: boolean = true,
	isLoggedIn: boolean = false
) {
	const mutation = CHECKOUT_UPSERT_MUTATION;

	const variables = {
		checkout: createAPICheckoutObjectFactory(
			checkout,
			user,
			experiments,
			isLoggedIn
		),
		id: checkoutID || checkout.id,
		validate: validate,
	};

	console.log(
		createAPICheckoutObjectFactory(checkout, user, experiments, isLoggedIn)
	);

	const apolloClient = initializeApollo();

	const checkoutResponse: {
		data: Checkout | null;
		errorMsg: string | null;
	} = await apolloClient
		.mutate({
			mutation: mutation,
			variables,
			errorPolicy: "all",
			context: { clientName: "checkoutAPI" },
		})
		.then((checkout: APIResponse<{ upsertCheckout: CheckoutAPIResponse }>) => {
			if (checkout.errors) {
				if (
					typeof window !== "undefined" &&
					!window.location.href.includes("localhost")
				) {
					Sentry.setContext("variables", {
						customer: variables.checkout.customer,
						address: variables.checkout.address,
						pet: variables.checkout.products?.[0]?.pet,
						subscription: variables.checkout.products?.[0]?.pet?.subscription,
					});
					Sentry.captureException(
						Object.values(checkout.errors[0]?.validation)
						.flatMap((msg) => msg)
						?.join(" ")
					);
				}

				let err;
				if (checkout.errors[0].validation) {
					err = Object.values(checkout.errors[0]?.validation).flatMap((msg) => msg)?.join(" ")
				} else if (checkout.errors[0].message) {
					err = checkout.errors[0].message;
				}

				return {
					errorMsg: err,
					data: null,
				};
			} else {
				console.log("--- checkout updated successfully ---");
				
				return {
					errorMsg: null,
					data: CreateCheckoutFromAPIData(checkout.data.upsertCheckout),
				};
			}
		})
		.catch((error: APIError) => {
			console.log("--- checkout errored --- ");
			console.log(error?.graphQLErrors?.[0]);
			console.log(error, variables);
			if (
				typeof window !== "undefined" &&
				!window.location.href.includes("localhost")
			) {
				Sentry.setContext("variables", {
					customer: variables.checkout.customer,
					address: variables.checkout.address,
					pet: variables.checkout.products?.[0]?.pet,
					subscription: variables.checkout.products?.[0]?.pet?.subscription,
				});
				Sentry.captureException(new Error(error.message));
			}
			
return { errorMsg: error.message,
data: null };
		});

	return checkoutResponse;
}

export async function validateCheckoutInfo (
	info: string,
	type: "email" | "phone" | "postcode"
) {
	const { mutation, variables } = {
		postcode: {
			mutation: VALIDATE_POSTCODE_MUTATION,
			variables: { post_code: info },
		},
		phone: { mutation: VALIDATE_PHONE_MUTATION,
variables: { phone: info } },
		email: { mutation: VALIDATE_EMAIL_MUTATION,
variables: { email: info } },
	}[type];

	const apolloClient = initializeApollo();
	
return await apolloClient
		.mutate({
			mutation: mutation,
			variables,
			errorPolicy: "all",
			context: { clientName: "checkoutAPI" },
		})
		.then((data: APIResponse<{ upsertCheckout: CheckoutAPIResponse }>) => {
			if (data.errors) {
				console.log(data);
				
return data.errors;
			} else {
				console.log(data);
				
return "success";
			}
		})
		.catch((error: APIError) => {
			console.log(error);
			console.error(error, variables);
			
return [{ message: "error, please try again" }];
		});
}

export async function addDiscountCode (checkout_id: string, code: string) {
	const mutation = ADD_DISCOUNT_CODE_MUTATION;
	const variables = {
		checkout_id: checkout_id,
		value: code,
	};

	const apolloClient = initializeApollo();

	return await apolloClient
		.mutate({
			mutation: mutation,
			variables,
			errorPolicy: "all",
			context: { clientName: "checkoutAPI" },
		})
		.then((checkout: APIResponse<{ upsertCheckout: CheckoutAPIResponse }>) => {
			if (checkout.errors) {
				console.log(JSON.stringify(checkout));
				//console.error(JSON.stringify(checkout));

				return checkout;
			} else {
				console.log(checkout);
				// need to setCheckout wherever this is used
				
				return CreateCheckoutFromAPIData(
					//ts-expect-error need to make a type for addCodeOrTokenToCheckout
					checkout.data.addCodeOrTokenToCheckout
				);
			}
		})
		.catch((error: APIError) => {
			console.log(JSON.stringify(error));
			//console.error(JSON.stringify(error), JSON.stringify(variables));
		});
}
